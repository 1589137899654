.container {
  background-color: var(--color-variant-black);
  width: 100%;
  padding: 65px 0px 30px 0px;
  color: var(--color-variant-white);
  font-family: var(--font-family-lexend);
}

.wrapper {
  width: 92%;
  margin: 0 auto;
  max-width: 1440px;
}

.top {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.location {
  font-size: calc(var(--font-size-md) - 2px);
  font-weight: 300;
  line-height: 27px;
  width: 368px;
  margin-top: 25px;
}

.link {
  display: flex;
  gap: 18px;
  margin-top: 25px;
}

.text {
  font-weight: 400;
  font-size: var(--font-size-md);
  color: var(--color-variant-white);
}

.contact {
  margin-top: 25px;
  max-width: 215px;
}

.contact p {
  font-weight: 400;
  color: #aaa;
  font-size: 18px;
}

.socials {
  display: flex;
  width: 214px;
  justify-content: space-between;
  margin-top: 25px;
}

.socials img {
  cursor: pointer;
}

.toRight ul {
  margin-top: 25px;
}

.toRight li {
  color: #aaa;
  font-weight: 300;
  font-size: 18px;
  padding: 5px 0px;
  cursor: pointer;
}

.toRight li:first-child {
  padding: 0px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 60px;
  font-weight: 400;
  color: #aaa;
  align-items: center;
  font-size: 18px;
}

.bottom span {
  color: var(--color-variant-green-1);
}

.outro {
  background-color: #1e1e1e;
  padding: 27px 60px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  font-family: var(--font-family-lexend);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 769px) {
  .top {
    flex-direction: column;
    gap: 45px;
  }

  .bottom {
    display: none;
  }

  .location {
    font-size: var(--font-size-sm);
    width: 250px;
  }
  .contact p,
  .link,
  .text,
  .toRight li {
    font-size: var(--font-size-sm);
  }

  .contact p {
    line-height: 1.5em;
  }

  .outro {
    padding: 27px 25px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 450px) {
  .outro {
    flex-direction: column; 
    gap: 10px; 
  }
}
