.container {
  width: 100%;
  font-family: var(--font-family-lexend);
  padding: 84px 0px;
  margin-top: 30px; 
}

.wrapper {
  width: 92%;
  max-width: 1440px;
  margin: 0 auto;
}

.header h1 {
  font-size: var(--font-size-lg);
  text-align: center;
  padding-bottom: 80px;
}

.content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

@media only screen and (max-width: 769px) {
  .container {
    min-width: 100%;
    padding-top: 0px; 
    padding-bottom: 50px;
  }

  .header h1 {
    font-size: var(--font-size-md);
    padding-bottom: 50px;
  }

  .content > div {
    width: 213px !important;
    height: 120px !important;
    /* padding: 20px 50px !important; */
  }
}
