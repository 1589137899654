.container {
  /* min-width: 1440px; */
  width: 100%;
  position: relative;
  overflow: hidden;
}

.wrapper {
  display: flex;
}

.toLeft {
  padding: 35px 80px;
  width: 50%;
  background-color: var(--color-variant-black);
  transition: width 0.5s;
}

.toRight {
  padding-top: 25px; 
  padding-bottom: 25px;
  /* padding-left: 14vw;  */
  width: 50%;
  background-color: var(--color-variant-white);
}

.content {
  margin-top: 78px;
  color: var(--color-variant-white);
  font-family: var(--font-family-lexend);
  padding-bottom: 8rem;
  z-index: 100;
  position: relative;
}

.content h1 {
  width: var(--content-width);
  font-weight: 300;
  font-size: var(--font-size-lg);
  line-height: 45px;
  letter-spacing: 0.02em;
}

.content p {
  font-size: var(--font-size-md);
  color: var(--color-variant-gray-1);
}

.content span {
  color: var(--color-variant-green-1);
  font-style: italic;
  font-family: var(--font-family-fraunces);
}

.long_text {
  margin-top: 78px;
  width: calc(var(--content-width) - var(--content-width-scale));
  font-weight: 300;
}

.short_text {
  margin-top: 60px;
  font-weight: 400;
  color: var(--color-variant-white) !important;
}

.btn {
  background: var(--color-variant-green-1);
  color: #010101;
  font-weight: 600;
  font-size: 17px;
  margin-top: 45px;
  border-radius: 28px;
  width: calc(var(--content-width) - var(--content-width-scale) * 4);
}

.hero_vector {
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero_image {
  position: absolute;
  /* top: 60%;
  left: 60%;
  transform: translate(-50%, -50%); */
}

.toBottom {
  /* top: 100%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 100%;
  height: 60px;
  background-color: var(--color-variant-white);
}

.bg_box {
  position: absolute;
  width: 600px;
  height: 800px;
  top: 70%;
  left: 0%;
  transform: translate(-20%, -60%);
  z-index: 50;
  background: rgb(16, 16, 16);
  background: linear-gradient(
    90deg,
    rgba(16, 16, 16, 1) 92%,
    rgba(14, 14, 14, 0) 100%
  );
}
@media only screen and (max-width: 1200px) {
.toRight {
   padding-right: 4rem !important;   
  }

}
@media only screen and (max-width: 1020px) {
.toRight {
   padding-right: 3rem !important;   
  }

}
@media only screen and (max-width: 992px) {
  .container {
    width: 100%;
    min-width: 100%;
  }

  .toLeft {
    width: 100%;
  }

  .toLeft {
    width: 100%;
    padding: 35px 20px;
  }

  .content {
    padding-bottom: 8rem;
  }

  .hero_vector {
    top: 95%;
  }

  .hero_image {
    width: 400px;
  }

  .logo_container {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 450px) {
  .content h1 {
    width: calc(var(--content-width) - var(--content-width-scale) * 7);
    font-size: var(--font-size-md);
    line-height: 30px;
  }

  .content p {
    font-size: var(--font-size-sm);
  }

  .long_text {
    margin-top: 18px;
    width: calc(var(--content-width) - var(--content-width-scale) * 7);
  }

  .hero_image {
    width: 350px;
  }
}
