.container {
  font-family: var(--font-family-lexend);
  margin-top: 10px;
  width: 845px;
}

.tabs_container {
  display: flex;
  border: 5px solid var(--color-variant-black-1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: min-content;
}

.tabs {
  background-color: var(--color-variant-black-1);
  padding: 12px 22px;
  border-right: 1px solid var(--color-variant-white);
  font-size: calc(var(--font-size-sm) + 2px);
  cursor: pointer;
}

.tabs:hover {
  color: var(--color-variant-green-1) !important;
}

.tabs:last-child {
  border-right: none;
}

.tab_content {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-variant-black-1);
  border-radius: 0px 15px 15px 15px;
  padding: 18px 45px;
  font-size: calc(var(--font-size-sm) + 2px);
  line-height: 30px;
  font-weight: 300;
}

.info_header {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.info {
  min-width: 200px;
}

.vehicle_name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 5px;
  width: 250px;
  text-align: center; 
  line-height: 14px;
  align-items: center; 
  min-height: 28px; 
}

.vehicle_name img {
  cursor: pointer;
}

@media only screen and (max-width: 1280px) {
  .container {
    width: 720px;
  }

  .tab_content {
    justify-content: flex-start;
    gap: 10px;
  }
  
  .vehicle_name {
  line-height: 12px;
    min-height: unset; 
}

  .topics {
    line-height: 26px;
    font-size: 14px;
  }

  .title p {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1080px) {
  .container {
    width: 690px;
  }
  .vehicle_name {
    width: 200px;
    gap: 0px; 
    font-size: 12px;
  }

  .vehicle_name img {
    transform: scale(0.5);
  }
}
