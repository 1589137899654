* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --font-family-fraunces: Fraunces, serif;
  --font-family-lexend: Lexend, sans-serif;
  --color-variant-green: #24ae4f;
  --color-variant-green-1: #24ff69;
  --color-variant-gray: #858585;
  --color-variant-gray-1: #c5c5c5;
  --color-variant-white: #ededed;
  --color-variant-white-1: #e2e2e2;
  --color-variant-black: #101010;
  --color-variant-black-1: #1e1e1e;

  /* max width of content */
  --content-width: 25rem;

  /* content width scaling ratio for mobile devices */
  --content-width-scale: 1.25rem;

  /* font sizes lg, md, and sm are variants with screen sizes */
  --font-size-xl: 40px;
  --font-size-lg: 35px;
  --font-size-md: 20px;
  --font-size-sm: 14px;
}


/* Media query for mobile devices */
@media only screen and (max-width: 768px) {

  /* Your styles for mobile devices */
  html {
    font-size: 16px;
  }
}

/* Media query for tablets */
@media only screen and (min-width: 769px) and (max-width: 1024px) {

  /* Your styles for tablets */
  html {
    font-size: 16px;
  }
}

/* Media query for desktops */
@media only screen and (min-width: 1025px)  and (max-width: 2048px){

  /* Your styles for desktops */
  html {
    font-size: 16px;
  }
}

/* Media query for desktops */
@media only screen and (min-width: 2049px) {

  /* Your styles for desktops */
  html {
    font-size: 18px;
  }
}

/* Media query for retina displays */
@media only screen and (min-resolution: 144dpi) {
  /* Your styles for retina displays */
  html {
    font-size: 18px;
  }
}


li {
  list-style-type: none; 
}

a {
  color: #aaa;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.button-variant-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  border: 2px solid var(--color-variant-green-1);
  border-radius: 22px;
  cursor: pointer;
}

.button-variant-2 {
  border: 1px solid;
  border-radius: 26px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
}

.button-variant-1:hover,
.button-variant-2:hover {
  opacity: 0.9;
}

body {
  overflow: visible !important; 
  padding-right: 0 !important; 
}

.MuiAlert-message {
  font-size: .9rem;
}
