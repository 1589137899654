.container {
  background-image: url("/public/images/banner_bg.png");
  background-color: black;
  background-blend-mode: luminosity;
  padding: 84px 20px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  font-family: var(--font-family-lexend);
  color: #fff;
  overflow: hidden;
  position: relative;
  min-height: 810px;
}

.banner_heading h1 {
  font-weight: 600;
  font-size: var(--font-size-xl);
  padding: 8px 0px;
}

.banner_heading p {
  font-size: 28px;
  padding: 8px 0px;
}

.banner_heading span {
  font-family: var(--font-family-fraunces);
  font-weight: 400;
  font-style: italic;
}

.btns {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 20px;
  font-family: var(--font-family-lexend);
  color: #101010;
  font-weight: 600;
}

.btn {
  background-color: var(--color-variant-green-1);
  font-weight: 600;
  padding: 18px 40px;
  font-size: var(--font-size-md);
  border-radius: 28px;
  color: var(--color-variant-black);
  font-family: var(--font-family-lexend);
  border-radius: 34px;
}

.banner_img {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.banner_img img {
  width: 600px;
}

@media only screen and (max-width: 769px) {
  .container {
    min-width: 100%;
    background-size: cover;
  }

  .banner_img {
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
  }

  .banner_heading h1 {
    font-size: var(--font-size-md);
  }

  .banner_heading p {
    font-size: 16px;
  }

  .btn {
    padding: 10px 20px;
    font-size: 11px;
  }

  .banner_img img {
    width: 450px;
  }
}

@media only screen and (max-width: 450px) {
  .container {
    min-height: 600px;
  }

  .banner_img {
    top: 50%;
    left: 95%;
    transform: translate(-50%, -50%);
  }

  .banner_img img {
    width: 300px;
  }
}
