.container {
  width: 100%;
}

.wrapper {
  display: flex;
}

.toLeft {
  width: 50%;
  background-color: var(--color-variant-black);
  padding: 185px 65px 198px 80px;
}

.toRight {
  width: 50%;
  background-color: var(--color-variant-black-1);
  padding: 150px 65px 50px 80px;
}

.toLeft h1 {
  color: var(--color-variant-white);
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  font-family: var(--font-family-lexend);
  width: 459px;
}

.cost_container {
  display: flex;
  gap: 20px;
}

.note {
  color: var(--color-variant-white);
  font-weight: 300;
  line-height: 27px;
  font-family: var(--font-family-lexend);
  font-size: 14px;
  width: 300px;
  width: 100%;
  margin-top: 6rem;
}

@media only screen and (max-width: 1200px) {
  .toLeft h1 {
    font-size: 28px;
    width: 380px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 992px) {
  .toLeft h1 {
    font-size: 23px;
    width: 350px;
  }

  .toLeft {
    padding: 185px 30px 198px 30px;
  }

  .toRight {
    padding: 150px 30px 50px 30px;
  }
}

@media only screen and (max-width: 769px) {
  .container {
    width: 100%;
    min-width: 100%;
  }

  .toRight {
    width: 100%;
    padding: 0px 10px 20px 10px;
  }

  .toLeft h1 {
    font-size: 20px;
    width: 280px;
    line-height: 25px;
  }

  .wrapper {
    flex-wrap: wrap;
  }

  .toLeft {
    width: 100%;
    padding: 30px 20px;
  }

  .toRight {
    background-color: var(--color-variant-black);
  }

  .note {
    font-size: 12px;
    margin-top: 2rem;
  }
}
