.container {
  padding: 20px;
  color: var(--color-variant-white);
  font-size: calc(var(--font-size-md) - 2px);
  font-family: var(--font-family-fraunces);
  border: 1px solid var(--color-variant-white);
  border-radius: 20px;
  margin-top: 18px;
  width: 230px;
}

.container p {
  line-height: 30px;
  margin-left: 20px;
}

@media only screen and (max-width: 1280px) {
  .container {
    width: 190px;
  }

  .container p {
    font-size: 16px;
    line-height: 25px;
    margin-left: 0px;
  }
}

@media only screen and (max-width: 769px) {
  .container {
    width: 130px;
  }

  .container p {
    font-size: 12px;
    line-height: 25px;
    margin-left: 0px;
  }
}
