.container {
  width: 100%;
  padding: 24px 0px;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
}

.top {
  display: flex;
  justify-content: space-between;
}

.wrapper ul {
  margin-top: 60px;
}

.wrapper li {
  font-family: var(--font-family-lexend);
  letter-spacing: 0.02em;
  color: #000;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer; 
}

.wrapper li {
  margin-top: 24px;
}

.btn {
  background-color: var(--color-variant-green);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  margin-top: 47px;
  border-color: var(--color-variant-green);
  font-family: var(--font-family-lexend);
  font-weight: 600;
}

.bottom {
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
  font-family: "Lexend";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #606060;
}

.bottom p {
  cursor: pointer; 
}