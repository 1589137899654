.container {
  width: 100%;
  background-color: var(--color-variant-black);
  padding: 116px 0px 55px 0px;
  font-family: var(--font-family-lexend);
  color: var(--color-variant-white);
}

.wrapper {
  width: 92%;
  max-width: 1440px;
  margin: 0 auto;
}

.content {
  display: flex;
  flex-direction: row;
  gap: 46px;
  justify-content: center;
}

.header h1 {
  font-weight: 600;
  font-size: calc(var(--font-size-lg) + 5px);
  line-height: 50px;
}

.header_text {
  font-size: calc(var(--font-size-md) + 4px);
  font-weight: 400;
  padding: 20px 0px;
}

.header_text span {
  color: var(--color-variant-green-1);
  font-style: italic;
  font-size: calc(var(--font-size-md) + 4px);
  font-family: var(--font-family-fraunces);
  font-weight: 400;
  margin: 0px 5px;
}

.mobile_cost_list {
  background-color: var(--color-variant-black-1);
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
  max-width: 326px;
  margin: 0 auto;
}

.mobile_cost_list_data {
  display: flex;
  align-items: center;
  gap: 20px;
}

.mobile_cost_lists {
  font-size: 12px;
  line-height: 25px;
  text-align: right;
}

.selector {
  display: flex;
  border: 5px solid var(--color-variant-black-1);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  width: min-content;
  margin: 0 auto; 
}

.selector_tab {
  background-color: var(--color-variant-black-1);
  padding: 12px 22px;
  border-right: 1px solid var(--color-variant-white);
  font-size: calc(var(--font-size-sm) + 2px);
  cursor: pointer;
}

.selector_tab:last-child {
  border-right: none;
}

@media only screen and (max-width: 992px) {
  .mobile_cost_list {
    max-width: 400px;
  }

  .mobile_cost_lists {
    font-size: 15px;
  }
}

@media only screen and (max-width: 769px) {
  .container {
    min-width: 100%;
    padding: 50px 0px 55px 0px;
  }

  .content {
    flex-wrap: wrap;
  }

  .toLeft {
    margin: 0 auto;
  }

  .toRight {
    margin: 0 auto;
  }

  .header_text {
    text-align: center;
    font-size: calc(var(--font-size-sm) + 2px);
  }

  .header h1 {
    font-size: var(--font-size-md);
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  .mobile_cost_list {
    max-width: 300px;
  }

  .mobile_cost_lists {
    font-size: 12px;
  }

  .mobile_cost_list {
    max-width: 100%;
    border-radius: 0px;
  }

  .wrapper {
    width: 100%;
  }

  .mobile_cost_list_data {
    justify-content: center;
  }

  .toRight {
    width: 100%;
  }
}
