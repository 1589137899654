.nav_links ul {
  display: flex;
  gap: 25px;
}

.nav_links li {
  cursor: pointer;
}

.nav_links {
  display: flex;
  gap: 25px;
  font-family: var(--font-family-lexend);
  font-size: 16px;
  align-items: center;
  justify-content: right;
}

.join_waitlist_btn {
  min-width: fit-content;
  border-color: var(--color-variant-green);
  color: var(--color-variant-green);
}

@media only screen and (max-width: 1465px) {
  .join_waitlist_btn {
    padding: 12px 16px;
  }

  .nav_links ul {
    gap: 22px;
  }
}

@media only screen and (max-width: 1295px) {
  .join_waitlist_btn_display_control {
    display: none;
  }

  .nav_links ul {
    gap: 32px;
  }

}
