.container {
  max-width: 350px;
  border: 2px solid var(--color-variant-white);
  border-radius: 20px;
  padding: 20px;
}

.animated {
  width: 260px;
  height: 200px;
}

.header_text {
  font-family: var(--font-family-fraunces) !important;
  font-size: 24px !important;
  line-height: 30px;
  font-style: italic;
  color: var(--color-variant-green-1) !important;
  font-weight: 400 !important;
  margin-bottom: 15px;
}

.content p {
  font-family: var(--font-family-lexend);
  color: var(--color-variant-white);
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
}

@media only screen and (max-width: 1280px) {
  .container {
    max-width: 250px;
    border: 2px solid var(--color-variant-green);
  }
  .content p {
    font-size: var(--font-size-sm);
  }

  .animated {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 769px) {
  .header_text {
    font-size: 16px !important;
  }

  .content p {
    font-size: 14px;
  }
}
