.container {
  width: 100%;
  padding: 54px 0px;
  background-color: var(--color-variant-white-1);
  font-family: var(--font-family-lexend);
}

.wrapper {
  width: 92%;
  margin: 0 auto;
  max-width: 1440px;
}

.wrapper h1 {
  text-align: center;
  font-size: var(--font-size-lg);
  padding-bottom: 82px;
  font-family: var(--font-family-lexend);
}

.logo_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 40px; 
}

@media only screen and (max-width: 769px) {
  .logo_container {
    flex-direction: column;
    gap: 3em;
  }

  .wrapper h1 {
    font-size: var(--font-size-md);
    padding-bottom: 30px;
  }
}
